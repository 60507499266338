.rmdp-container {       
  vertical-align: middle !important;
}
.rmdp-input {
  font-size: 15px !important;
  padding: 11px 15px!important;
  width: 260px !important;
  height: auto !important;
  border-radius: 0 !important;
  margin: 0 !important;
  cursor: pointer; 
}
.rmdp-day-picker .rmdp-today span{
  background: #244895 !important;
  border-radius: 50% !important;
}
.rmdp-month-picker .rmdp-today span,
.rmdp-year-picker .rmdp-today span{
  border-radius: 12px !important;
}

.rmdp-range {
  background: rgba(36,72,149, .1) !important;
  color: #244895 !important;
  font-weight: bold !important;
  box-shadow: none !important;
}
.rmdp-range.start,
.rmdp-range.end {
  background: #244895 !important;
  color: #fff !important;
}
.rmdp-range.start {
  border-top-left-radius: 12% !important;
  border-bottom-left-radius: 12% !important;
}
.rmdp-range.end {
  border-top-right-radius: 12% !important;
  border-bottom-right-radius: 12% !important;
}

.icon-tabler-calendar-event {
  margin-right: 10px !important;
}