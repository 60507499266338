@font-face {
	font-family: 'SUIT';
	font-weight: 100;
	src: url('@static/fonts/SUIT-Thin.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 200;
	src: url('@static/fonts/SUIT-ExtraLight.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 300;
	src: url('@static/fonts/SUIT-Light.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 400;
	src: url('@static/fonts/SUIT-Regular.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 500;
	src: url('@static/fonts/SUIT-Medium.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 600;
	src: url('@static/fonts/SUIT-SemiBold.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 700;
	src: url('@static/fonts/SUIT-Bold.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 800;
	src: url('@static/fonts/SUIT-ExtraBold.woff2') format('woff2');
}
@font-face {
	font-family: 'SUIT';
	font-weight: 900;
	src: url('@static/fonts/SUIT-Heavy.woff2') format('woff2');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SUIT';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body{
  min-height: 100%;
}
table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
  border: none;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
button {
  cursor: pointer;
}
input {
  outline: none;
  border-radius: 0;
}
.react-datepicker-wrapper {
  padding: 12px 0 !important;
  width: auto !important;
}

.react-datepicker__input-container input {
  border: none;
  font-size: 16px;
  width: 100px;
  cursor: pointer;
}